import Phaser from "../../images/sites/target-shoot.jpg";

export const SiteListDataPhaser = [
  {
    id: "1",
    pathref: "../../TargetShoot/targetShoot.html",
    src: Phaser,
    alt: "Phaser site",
    target: "",
  },
];
